import styled from '@emotion/styled'

export const EventsSection = styled.div`
  margin-top: ${({ theme }) => theme.spacing.medium};
  width: 100%;
`

export const ArticleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`
