import * as React from 'react'
import { graphql } from 'gatsby'
import { useRecoilValue } from 'recoil'
import { isMobileState } from '@/recoil/layout'
import { Helmet } from 'react-helmet'
import { useLocation } from '@reach/router'
import Layout from '@/layouts/Default'
import Events from '@/components/Events'
import ArticleHero from '@/components/ArticleHero'
import ArticleTitle from '@/components/ArticleTitle'
import ArticleBlockImage from '@/components/ArticleBlockImage'
import ArticleBlockText from '@/components/ArticleBlockText'
import ArticleBlockSimpleCaps from '@/components/ArticleBlockSimpleCaps'
import ArticleBlockHeadline from '@/components/ArticleBlockHeadline'
import ArticleBlockQuote from '@/components/ArticleBlockQuote'
import ArticleBlockCTA from '@/components/ArticleBlockCTA'
import ArticleBlockVideo from '@/components/ArticleBlockVideo'

import moment from 'moment'

import { ArticleWrapper, EventsSection } from './article.style'

const ArticlePage = ({ data, pageContext: { requireAgeGate, pagePath, marketCode: pageMarketCode } }) => {
  const isMobile = useRecoilValue(isMobileState)
  const content = data.drupal.nodeById.fieldNewsBodyLayer
  const date = moment(data.drupal.nodeById?.fieldNewsDate?.date).format('LL')
  const categories = data.drupal.nodeById?.fieldNewsCategories?.map((cat) => cat.entity?.name).join(', ')
  const location = useLocation()

  const events = data.drupal.eventsQuery.entities.map((event, index) => {
    const event_date = event?.fieldEventEndDate?.value ? event?.fieldEventEndDate?.value : event?.fieldEventStartDate?.value
    return {
      name: event?.title,
      url: event?.path?.alias,
      startDate: event?.fieldEventStartDate?.value,
      endDate: event?.fieldEventEndDate?.value,
      onGoing: moment.utc(new Date()).isBefore(event_date),
      ref: event?.fieldEventLocation,
      hours: event?.fieldEventHours,
      country: event?.fieldEventCountryLocation,
      city: event?.fieldEventLocation,
      content: event?.fieldEventDescription,
      images: [event?.fieldEventCover?.url],
      timestamp: moment(event?.fieldEventStartDate?.value).unix()
    }
  })
    .filter((event) => event.onGoing)
    .sort((ea, eb) => ea.timestamp - eb.timestamp)

  return (
    <Layout entityBundle='news' path={ pagePath } requireAgeGate={ requireAgeGate } pageType='Editorial' contentType={ categories } plainHeader pageMarketCode={ pageMarketCode }>
      <ArticleWrapper>
        <Helmet>
          <title>{data.drupal.nodeById?.fieldNewsMetaTags.entity.fieldMetaTitle}</title>
          <meta name='description' content={ data.drupal.nodeById?.fieldNewsMetaTags.entity.fieldMetaDescription } />
          <meta property='og:title' content={ data.drupal.nodeById?.fieldNewsMetaTags.entity.fieldMetaTitle } />
          <meta property='og:description' content={ data.drupal.nodeById?.fieldNewsMetaTags.entity.fieldMetaDescription } />
          <meta property='og:url' content={ `${process.env.URL || process.env.GATSBY_DRUPAL_URL}${pagePath}` } />
          <meta property='og:site_name' content='Cloudy Bay' />
          <meta property='og:type' content='article' />
          {data.drupal.nodeById?.fieldNewsMetaTags.entity.fieldMetaOpenGraphImage && <meta property='og:image' content={ data.drupal.nodeById?.fieldNewsMetaTags.entity.fieldMetaOpenGraphImage?.url } /> }
        </Helmet>

        <ArticleHero
          img={ isMobile ? data.drupal.nodeById?.fieldNewsCoverMobile?.url : data.drupal.nodeById?.fieldNewsCover?.url }
          ctaLabel='back'
          cta='/journal'
        />

        <ArticleTitle
          infos={ `${date} - ${categories}` }
          title={ data.drupal.nodeById?.fieldNewsTitle }
          introduction={ data.drupal.nodeById?.fieldNewsIntroduction }
          shareURL={ location.href }
          ctaLabel='back'
          cta='/journal'
        />

        {content.map((row) => {
          switch (row.entity.type.targetId) {
            case 'simple_caps_section':
              return (
                <ArticleBlockSimpleCaps
                  text={ row.entity.fieldSimpleCapsSectContent }
                />
              )

            case 'headline':
              return (
                <ArticleBlockHeadline
                  text={ row.entity.fieldHeadlineContent }
                />
              )

            case 'simple_text_1_column':

              return (
                <ArticleBlockText
                  text1={ row.entity.fieldSimpTxt1colSectContent }
                />
              )

            case 'simple_text_2_columns':
              return (
                <ArticleBlockText
                  text1={ row.entity.fieldSimpTxt2colTxtColumn1 }
                  text2={ row.entity.fieldSimpTxt2colTxtColumn2 }
                />
              )

            case 'simple_image':
              return (
                <ArticleBlockImage
                  src={ isMobile ? row.entity.fieldSimpleImageMobile?.url : row.entity.fieldSimpleImageDesktop?.url }
                />
              )

            case 'standard_quote_with_author':
              return (
                <ArticleBlockQuote
                  author={ row.entity.fieldStandardQuoteAuthorName }
                  quote={ row.entity.fieldStandardQuoteAuthorText }
                />
              )

            case 'cta_block':
              return (
                <ArticleBlockCTA
                  to={ row.entity.fieldCtaBlockCta?.url?.path }
                  label={ row.entity.fieldCtaBlockCta?.title }
                />
              )

            case 'file_linik':
              return (
                <ArticleBlockCTA
                  to={ row.entity.fieldStandardFile?.entity?.url }
                  label={ row.entity.fieldStandardFile?.description }
                  target='_blank'
                />
              )

            case 'standard_single_video':
              return (
                <ArticleBlockVideo
                  url={ row.entity.fieldStandardSingleVimeoLink?.uri }
                />
              )
          }
        })}

        {events.length > 0 && (
          <EventsSection>
            <Events
              title='Upcoming Events'
              subtitle='events'
              items={ events }
            />
          </EventsSection>
        )}
      </ArticleWrapper>
    </Layout>
  )
}

export default ArticlePage

export const pageQuery = graphql`
query ArticleQuery($nid: String!){
  drupal {
    nodeById(id: $nid) {
      title
      ... on Drupal_NodeNews {
        nid
        fieldNewsTitle
        fieldNewsShortDescription
        fieldNewsIntroduction
        fieldNewsMetaTags {
          entity {
            ... on Drupal_ParagraphMetaTags {
              fieldMetaTitle
              fieldMetaDescription
              fieldMetaOpenGraphImage {
                url
              }
            }
          }
        }
        fieldNewsDate {
          date
        }
        fieldNewsCoverMobile {
          alt
          url
          title
        }
        fieldNewsCover {
          alt
          title
          url
        }
        fieldNewsCategories {
          entity {
            ... on Drupal_TaxonomyTermNewsCategories {
              entityRendered
              tid
              name
            }
          }
        }
        fieldNewsBodyLayer {
          entity {
            id
            parentFieldName
            type {
              targetId
            }
            ... on Drupal_ParagraphSimpleText1Column {
              id
              fieldSimpTxt1colSectContent
            }
            ... on Drupal_ParagraphHeadline {
              id
              fieldHeadlineContent
            }
            ... on Drupal_ParagraphSimpleCapsSection {
              id
              fieldSimpleCapsSectContent
            }
            ... on Drupal_ParagraphSimpleText2Columns {
              id
              fieldSimpTxt2colTxtColumn1
              fieldSimpTxt2colTxtColumn2
            }
            ... on Drupal_ParagraphSimpleImage {
              id
              fieldSimpleImageDesktop {
                alt
                url
              }
              fieldSimpleImageMobile {
                alt
                url
              }
            }
            ... on Drupal_ParagraphStandardQuoteWithAuthor {
              id
              fieldStandardQuoteAuthorName
              fieldStandardQuoteAuthorText
            }
            ... on Drupal_ParagraphCtaBlock {
              id
              fieldCtaBlockCta {
                url {
                  path
                }
                title
              }
            }
            ...on Drupal_ParagraphStandardSingleVideo {
              fieldStandardSingleVimeoLink {
                uri
              }
            }
          }
        }
      }
    }
    eventsQuery: nodeQuery(
      filter: {conditions: [
          {field: "type", value: "events", operator: EQUAL},
          # {field: "field_event_country", value: [$marketId], operator: EQUAL},
          {field: "field_event_types", value: "journal", operator: EQUAL}
          {operator: EQUAL, field: "status", value: "1"}
        ]}
        limit: 1000
      ) {
      entities {
        ... on Drupal_NodeEvents {
          title
          path{
            alias
          }
          fieldEventStartDate {
            value
            date
          }
          fieldEventEndDate {
            value
            date
          }
          fieldEventCountry {
            targetId
          }
          fieldEventCountryLocation
          fieldEventLocation
          fieldEventCover {
            height
            url
            width
            targetId
            alt
            title
          }
          fieldEventCoverMobile {
            height
            url
            width
            targetId
            alt
            title
          }
          fieldEventDescription
          fieldEventHours
        }
      }
    }
  }
}
`
